export const DEFAULTS = {
  alignItems: 'start',
  autoplay: false,
  controls: {
    alignment: 'start',
    position: 'after',
  },
  duration: 500,
  /**
   * Defaults to a tailwind util class, but prop accepts a CSS
   * `transition-timing-function` value
   */
  easing: 'ease-in-out',
  /** @todo Not implemented yet */
  indicators: false,
  interval: 5000,
  loop: true,
  minItems: 5,
  reverse: false,
  touchThreshold: 44,
} as const;
