import React, {useEffect, useRef, useState} from 'react';

import {twMerge} from '@/stylesheets/twMerge';

import {DEFAULTS} from './constants';
import {useCarousel} from './useCarousel';
import type {CarouselProps} from './types';
import {Controls} from './Controls';
import {styles, childStyles, trackStyles} from './styles';
import {getMinItems, getOrder, getTranslateX, getWidth} from './utils';

/**
 * Render an array of `children` in a carousel with options for autoplay, custom
 * controls and more.
 *
 * @example
 *
 * <Carousel>
 *   {cards.map(card => <Card {...card} />)}
 * </Carousel>
 */
export default function Carousel({
  alignItems = DEFAULTS.alignItems,
  autoplay = DEFAULTS.autoplay,
  children,
  className,
  controls: rawControls,
  duration,
  easing,
  gap,
  interval = DEFAULTS.interval,
  loop = DEFAULTS.loop,
  maxWidth,
  reverse = DEFAULTS.reverse,
  visibleItems,
  ...restProps
}: CarouselProps) {
  const {
    currentIndex,
    direction,
    endIndexChange,
    fireOnce,
    globalAriaLabels,
    isEnd,
    isPlaying,
    isReducedMotion,
    isStart,
    lastInteraction,
    Observe,
    setIsPlaying,
    setUserPaused,
    size,
    startIndexChangeTo,
    userPaused,
  } = useCarousel(children, {autoplay, loop, reverse});
  const [touchStartX, setTouchStartX] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const controls = {...DEFAULTS.controls, ...rawControls};
  const controlsAfter = controls.position === 'after';
  const minChildItems = getMinItems(children, visibleItems);
  const minItemsSize = minChildItems.length;
  const shouldAutoplay = autoplay && !isReducedMotion;
  const maxWidthCalc =
    maxWidth && `calc(50% - ${maxWidth / 2}px - var(--margin))`;
  const shouldSetPlay = shouldAutoplay && !userPaused;

  /**
   * Handle `autoplay`
   */
  useEffect(() => {
    if (userPaused || !isPlaying) return;

    const id = setTimeout(() => {
      startIndexChangeTo(reverse ? 'prev' : 'next');
    }, interval);

    return () => {
      clearTimeout(id);
    };
  }, [
    isPlaying,
    userPaused,
    interval,
    reverse,
    startIndexChangeTo,
    currentIndex,
  ]);

  /**
   * Exit early or throw if 0 or 1 items are passed
   */
  if (children === undefined) return null;
  if (!Array.isArray(children)) {
    throw new Error(
      '1 item was passed via `children`, carousels need more than 1 to work.',
    );
  }

  function handleTouchStart({touches}: TouchEvent) {
    setTouchStartX(touches[0].clientX);
  }

  function handleTouchMove({touches}: TouchEvent) {
    if (fireOnce.current.setNewIndex) return;

    const lastSwipeXNotZero = lastInteraction.current.swipeX !== 0;
    const startX = lastSwipeXNotZero
      ? lastInteraction.current.swipeX
      : touchStartX;
    const touchMoveX = touches[0].clientX;
    const diff = Math.abs(touchMoveX - startX);

    if (diff < DEFAULTS.touchThreshold) return;

    /**
     * Prevent continued touchMove events in the same direction from triggering
     * further index changes, but allows the opposite direction to trigger a
     * change
     */
    if (lastSwipeXNotZero) {
      if (
        (lastInteraction.current.swipeDir === 1 && touchMoveX < startX) ||
        (lastInteraction.current.swipeDir === -1 && touchMoveX > startX)
      )
        return;
    }

    lastInteraction.current.swipeX = touchMoveX;

    if (touchMoveX < startX) {
      startIndexChangeTo('next');
      lastInteraction.current.swipeDir = 1;
    }
    if (touchMoveX > startX) {
      startIndexChangeTo('prev');
      lastInteraction.current.swipeDir = -1;
    }
  }

  function handleTouchEnd() {
    lastInteraction.current.swipeX = 0;
    lastInteraction.current.swipeDir = 0;
  }

  function getControls() {
    const _isStart = !loop && isStart;
    const _isEnd = !loop && isEnd;
    const nextIndexChange = () => startIndexChangeTo('next');
    const prevIndexChange = () => startIndexChangeTo('prev');
    const playPauseFlip = () => {
      setUserPaused((prev) => !prev);
      setIsPlaying((prev) => !prev);
    };

    return controls.renderFn ? (
      controls.renderFn({
        alignment: controls.alignment,
        ariaLabels: globalAriaLabels,
        autoplay: shouldAutoplay,
        isEnd: _isEnd,
        isPlaying,
        isStart: _isStart,
        mode: controls.mode,
        onNextClick: nextIndexChange,
        onPlayPauseClick: playPauseFlip,
        onPrevClick: prevIndexChange,
        reverse,
      })
    ) : (
      <Controls
        alignment={controls.alignment}
        ariaLabels={globalAriaLabels}
        autoplay={shouldAutoplay}
        className={controls.className}
        isEnd={_isEnd}
        isPlaying={isPlaying}
        isStart={_isStart}
        mode={controls.mode}
        onNextClick={nextIndexChange}
        onPlayPauseClick={playPauseFlip}
        onPrevClick={prevIndexChange}
        reverse={reverse}
      />
    );
  }

  return (
    <div
      ref={containerRef}
      role="region"
      aria-roledescription={globalAriaLabels.name}
      data-component-name="carousel"
      className={twMerge(
        styles({hasMaxWidth: maxWidth !== undefined}),
        className,
      )}
      style={{
        paddingLeft: maxWidthCalc,
        paddingRight: maxWidthCalc,
      }}
      {...restProps}
    >
      {shouldAutoplay && <Observe refObj={containerRef} />}
      {!controlsAfter && getControls()}

      <div
        role="list"
        data-name="carousel-track"
        className={trackStyles({alignItems})}
        style={{
          columnGap: gap ? `${gap}px` : undefined,
        }}
        onMouseEnter={shouldSetPlay ? () => setIsPlaying(false) : undefined}
        onMouseLeave={shouldSetPlay ? () => setIsPlaying(true) : undefined}
      >
        {React.Children.map(minChildItems, (child, idx) => {
          const isAriaHidden = idx > size - 1;
          /**
           * @note Using `cloneElement` here to enable the cleanest developer
           * experience when composing the carousel. I'm aware that the React
           * team does not recommend using `cloneElement` in most cases, but
           * the alternatives are render props, accepting an array of children
           * via props + another prop for the child component function or
           * leveraging Context. All of which aren't as nice to use as
           * simple composition via children
           */
          return React.cloneElement(child, {
            ...child.props,
            key: `carouselChild${idx}`,
            role: 'listitem',
            'aria-current': idx === currentIndex ? 'true' : null,
            'aria-hidden': isAriaHidden ? 'true' : null,
            'aria-label': `${globalAriaLabels.slide} ${idx + 1}`,
            tabIndex: isAriaHidden || idx !== currentIndex ? -1 : 0,
            className: twMerge(
              childStyles({
                duration: duration === undefined,
                easing: easing === undefined,
              }),
              child.props.className,
            ),
            style: {
              order: getOrder(idx, currentIndex, minItemsSize),
              transform: getTranslateX(direction, minItemsSize, gap, fireOnce),
              transitionProperty: direction === 0 ? 'none' : undefined,
              transitionDuration: duration !== undefined && `${duration}ms`,
              transitionTimingFunction: easing,
              width: getWidth(visibleItems, gap),
              zIndex: idx === currentIndex ? 1 : undefined,
            },
            onKeyDown: ({key}: React.KeyboardEvent) => {
              if (key === 'ArrowLeft') startIndexChangeTo('prev');
              if (key === 'ArrowRight') startIndexChangeTo('next');
            },
            onFocus: shouldSetPlay ? () => setIsPlaying(false) : undefined,
            onBlur: shouldSetPlay ? () => setIsPlaying(true) : undefined,
            onTouchStart: handleTouchStart,
            onTouchMove: handleTouchMove,
            onTouchEnd: handleTouchEnd,
            onTransitionEnd: endIndexChange,
          });
        })}
      </div>

      {controlsAfter && getControls()}
    </div>
  );
}
