import {cva} from 'class-variance-authority';

import {DEFAULTS} from './constants';

export const styles = cva('relative flex flex-col gap-4 overflow-x-clip', {
  variants: {
    hasMaxWidth: {
      true: 'max-xl:w-full',
      false: 'w-full',
    },
  },
});

/**
 * `justify-center` here is crucial to ensure that the current item in the
 * carousel renders in the center.
 */
export const trackStyles = cva('flex justify-center w-full', {
  variants: {
    alignItems: {
      start: 'items-start',
      center: 'items-center',
      end: 'items-end',
    },
  },
});

export const childStyles = cva(
  [
    'shrink-0 transition-transform will-change-transform focus:outline-none',
    'focus-within:ring-1 focus-within:ring-shade-40 focus-within:ring-opacity-50',
  ],
  {
    variants: {
      duration: {true: `duration-${DEFAULTS.duration}`},
      easing: {true: DEFAULTS.easing},
    },
  },
);
