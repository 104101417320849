import {cva} from 'class-variance-authority';

import InterfaceButton from '@/components/base/elements/InterfaceButton/InterfaceButton';
import {IconEnum} from '@/enums';
import {twMerge} from '@/stylesheets/twMerge';

import type {ControlsProps} from './types';

const styles = cva('flex gap-3 items-center', {
  variants: {
    alignment: {
      start: '',
      center: 'justify-center',
      end: 'justify-end',
    },
  },
});

export function Controls({
  alignment,
  ariaLabels,
  autoplay,
  className,
  isEnd,
  isPlaying,
  isStart,
  mode,
  onNextClick,
  onPlayPauseClick,
  onPrevClick,
  reverse,
}: ControlsProps) {
  return (
    <div
      role="group"
      aria-label={ariaLabels.controls}
      className={twMerge(styles({alignment}), className)}
    >
      <InterfaceButton
        ariaLabel={ariaLabels.previous}
        onClick={onPrevClick}
        iconProps={{
          icon: IconEnum.Previous,
        }}
        isDisabled={isStart}
        mode={mode}
      />
      {autoplay && (
        <InterfaceButton
          ariaLabel={isPlaying ? ariaLabels.play : ariaLabels.pause}
          onClick={onPlayPauseClick}
          iconProps={{
            icon: IconEnum.PlayPause,
            options: {isPlaying},
          }}
          isDisabled={reverse ? isStart : isEnd}
          mode={mode}
        />
      )}
      <InterfaceButton
        ariaLabel={ariaLabels.next}
        onClick={onNextClick}
        iconProps={{
          icon: IconEnum.Next,
        }}
        isDisabled={isEnd}
        mode={mode}
      />
    </div>
  );
}
