import {DEFAULTS} from './constants';
import type {
  CarouselProps,
  ShouldPauseOptions,
  UseCarouselFireOnce,
} from './types';

type Gap = CarouselProps['gap'];
type VisibleItems = CarouselProps['visibleItems'];
type FireOnce = UseCarouselFireOnce['fireOnce'];

/**
 * Return a value within bounds of 0 and `max`
 *
 * Used to keep carousel indexes within bounds of the carousel items length,
 * `max` being the length of the items.
 */
export function toRange(val: number, max: number) {
  if (max === 0) return 0;
  const index = val % max;
  // Avoids -0 weirdness with some negative `val` values
  return Math.max(0, index < 0 ? index + max : index);
}

/**
 * Ensure there are always enough items in the carousel to seamlessly transition
 */
export function getMinItems(items: any[], visibleItems?: number) {
  const minItems = DEFAULTS.minItems;
  const size = items.length;
  const visibleMoreThanMin = visibleItems && visibleItems + 2 > minItems;

  if (size < minItems || visibleMoreThanMin) {
    const itemsCopy = [...items];
    const diff = minItems - size;
    const rightOperand = visibleMoreThanMin ? visibleItems + 2 : minItems;

    /**
     * Make sure the last `itemsCopy` item matches the last item in `items` for
     * a seamless loop
     */
    while (itemsCopy.length < rightOperand) {
      itemsCopy.push(...items.slice(0, minItems - diff));
    }

    return itemsCopy;
  }

  return items;
}

/**
 * Get a value for the CSS `order` property, used to reorder items as the
 * `<Carousel>` cycles through indexes.
 *
 * Values are calculated to keep the current item in the center of the flex
 * container, which itself is `justify-content: center` with overflow.
 */
export function getOrder(idx: number, currIdx: number, size: number) {
  const half = size / 2;
  const offset = half % 1 === 0 ? half : Math.floor(half);
  return toRange(idx + offset - currIdx, size);
}

export function getTranslateXCalc(percentage: number, gap: number) {
  const operator = percentage > 0 ? '+' : '-';
  return `calc(${percentage}% ${operator} ${gap}px)`;
}

/**
 * Responsible for handling values for `transform` as the `<Carousel>` cycles
 * through indexes.
 */
export function getTranslateX(
  direction: number,
  childrenSize: number,
  gap: Gap,
  fireOnce: FireOnce,
) {
  const evenChildren = childrenSize % 2 === 0;
  let val = evenChildren ? -50 : 0;
  let _gap = gap ?? 0;
  let calc = getTranslateXCalc(val, _gap / 2);

  switch (direction) {
    /**
     * Previous
     */
    case -1:
      val = evenChildren ? 50 : 100;
      _gap = evenChildren ? _gap / 2 : _gap;
      calc = getTranslateXCalc(val, _gap);
      break;
    /**
     * Next
     */
    case 1:
      val = evenChildren ? -150 : -100;
      _gap = evenChildren ? _gap * 1.5 : _gap;
      calc = getTranslateXCalc(val, _gap);
      break;
    /**
     * In place rendering within the flex container, neither next or previous.
     * This case can be triggered on initial render or on the next render after
     * `next()` or `previous()` render.
     */
    case 0:
    default:
      /**
       * Reset `fireOnce` to allow `next()`, `previous()` and `setNewIndex()` to
       * fire again
       */
      if (fireOnce.current.nextPrev || fireOnce.current.setNewIndex) {
        fireOnce.current.nextPrev = false;
        fireOnce.current.setNewIndex = false;
      }
  }

  // Do not set `transform: translateX(...)`
  if (val === 0) return undefined;

  return `translateX(${_gap > 0 ? calc : `${val}%`})`;
}

export function getFixedVal(val: number) {
  return val % 1 === 0 ? val : val.toFixed(2);
}

/**
 * Responsible for setting the width of the carousel items should `visibleItems`
 * be configured.
 */
export function getWidth(visibleItems?: VisibleItems, gap?: Gap) {
  if (visibleItems === undefined || visibleItems <= 0) return undefined;

  const val = getFixedVal(100 / visibleItems);
  const percentVal = `${val}%`;

  return gap && gap > 0 ? `calc(${percentVal} - ${gap}px)` : percentVal;
}

/**
 * Determine if the carousel should pause when NOT looping, taking `reverse`
 * into account.
 *
 * When not looping and reversed, we want to avoid setting `isPlaying` to
 * `false` on the initial render when `isStart` is `true`. Using the `fireOnce`
 * ref to exclude that scenario.
 */
export function getShouldPause({
  fireOnce,
  isEnd,
  isPlaying,
  isStart,
  loop,
  reverse,
}: ShouldPauseOptions) {
  if (loop || !isPlaying) return false;
  if (!reverse && isEnd) return true;

  if (!isStart && !fireOnce.current.initialIndexChanged) {
    fireOnce.current.initialIndexChanged = true;
  }

  if (reverse && isStart && fireOnce.current.initialIndexChanged) {
    return true;
  }

  return false;
}
