import {useCallback, useRef, useState} from 'react';

import useReducedMotion from '@/hooks/useReducedMotion';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import type {
  AriaLabels,
  UseCarouselOptions,
  CarouselHookOutput,
  ObserveProps,
} from '@/components/shared/Carousel/types';
import {DEFAULTS} from '@/components/shared/Carousel/constants';
import {getShouldPause, toRange} from '@/components/shared/Carousel/utils';

/**
 * Generic carousel hook, providing helpers to handle any carousel
 */
export function useCarousel(
  items: any[],
  options?: UseCarouselOptions,
): CarouselHookOutput {
  /**
   * @todo Temporary until `global.json` are made in a follow up with Argo
   * @todo Wrap `t()` in `useMemo()`
   */
  const globalAriaLabels: AriaLabels = {
    name: 'Carousel',
    slide: 'Slide',
    controls: 'Carousel controls',
    play: 'Play carousel',
    pause: 'Pause carousel',
    next: 'Next slide',
    previous: 'Previous slide',
  };
  const _options = {
    autoplay: DEFAULTS.autoplay,
    loop: DEFAULTS.loop,
    reverse: DEFAULTS.reverse,
    ...options,
  };
  const {autoplay, loop, reverse} = _options;
  const isReducedMotion = useReducedMotion();
  const [currentIndex, _setCurrentIndex] = useState(0);
  const [newIndex, _setNewIndex] = useState(currentIndex);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [userPaused, setUserPaused] = useState(false);
  const [isPlaying, setIsPlaying] = useState(
    autoplay && !isReducedMotion && isIntersecting,
  );

  /**
   * Prevents `next/previous()` from being called on every `transitionend` event
   * as well as providing rapid click protection for `setNewIndex()`
   */
  const fireOnce = useRef({
    initialIndexChanged: false,
    nextPrev: false,
    setNewIndex: false,
  });
  /**
   * Used to:
   * - Ensure items of length 2 loop seamlessly (next/prev)
   * - Store swipe details for touch events without causing re-renders
   */
  const lastInteraction = useRef({
    next: false,
    prev: false,
    swipeX: 0,
    swipeDir: 0,
  });

  const currentItem = items[currentIndex];
  const size = items.length;
  const direction = getDirection(newIndex);
  const isStart = currentIndex === 0;
  const isEnd = currentIndex === size - 1;
  const shouldPause = getShouldPause({
    fireOnce,
    isEnd,
    isPlaying,
    isStart,
    loop,
    reverse,
  });

  if (shouldPause) {
    setIsPlaying(false);
  }

  /**
   * @note Wrapped in `useCallback` to avoid unnecessary re-renders
   */
  const getPreviousIndex = useCallback(
    (_currentIndex: number) => toRange(_currentIndex - 1, size),
    [size],
  );

  /**
   * @note Wrapped in `useCallback` to avoid unnecessary re-renders
   */
  const getNextIndex = useCallback(
    (_currentIndex: number) => toRange(_currentIndex + 1, size),
    [size],
  );

  /**
   * Set `newIndex`, used as an interim state prior to `currentIndex` being set
   * to allow time to perform transforms before next update
   *
   * @note Wrapped in `useCallback` to avoid unnecessary re-renders
   */
  const setNewIndex = useCallback(
    (type: 'next' | 'prev') => {
      _setNewIndex((prevNewIndex) => {
        return type === 'next'
          ? getNextIndex(prevNewIndex)
          : getPreviousIndex(prevNewIndex);
      });
    },
    [getNextIndex, getPreviousIndex],
  );

  function setCurrentIndex(index: number) {
    _setCurrentIndex(toRange(index, size));
  }

  function getPreviousItem() {
    return items[getPreviousIndex(currentIndex)];
  }

  function getNextItem() {
    return items[getNextIndex(currentIndex)];
  }

  /**
   * Advance to the next carousel index
   */
  function next() {
    _setCurrentIndex((prevCurrentIndex) => getNextIndex(prevCurrentIndex));
  }

  /**
   * Advance to the previous carousel index
   */
  function previous() {
    _setCurrentIndex((prevCurrentIndex) => getPreviousIndex(prevCurrentIndex));
  }

  /**
   * Begins the transition to the next or previous index, updating the interim
   * `newIndex` state to allow transforms to occur. `onTransitionEnd`
   * `endIndexChange()` in turn calls the `next()` or `previous()`
   * `useCarousel()` methods to trigger a re-render with updated `currentIndex`
   *
   * In most scenarios this will be called `onClick` by the `<Controls>` or
   * `onTouchMove` by the `<Carousel>` component.
   */
  const startIndexChangeTo = (type: 'next' | 'prev') => {
    if (fireOnce.current.setNewIndex) return;

    setNewIndex(type);

    if (items.length === 2) {
      lastInteraction.current[type] = true;
    }

    // Prevent rapid clicking on next/prev buttons in `<Controls>`
    fireOnce.current.setNewIndex = true;
  };

  /**
   * Completes the transition to the next or previous index. When the carousel
   * children have finished transitioning, step `currentIndex`.
   *
   * In most scenarios this will be called by the `onTransitionEnd` event in the
   * `<Carousel>` component.
   */
  function endIndexChange() {
    if (currentIndex === newIndex || fireOnce.current.nextPrev) return;

    if (direction === -1) {
      previous();
      lastInteraction.current.prev = false;
    }

    if (direction === 1) {
      next();
      lastInteraction.current.next = false;
    }

    // Prevent every `transitionend` event from firing `next/previous()`
    fireOnce.current.nextPrev = true;
  }

  /**
   * Get the direction of the carousel rotation
   */
  function getDirection(newIdx: number) {
    if (newIdx === currentIndex) return 0;

    /**
     * Handle items of length 2 differently based on whether `next` or `previous`
     * was the last interaction in the carousel. We want to persist the direction
     * in these cases to keep looping seamlessly between the two items.
     */
    if (items.length === 2) {
      if (lastInteraction.current.next) {
        return 1;
      }
      if (lastInteraction.current.prev) {
        return -1;
      }
    }

    if (currentIndex === 0 && newIdx === size - 1) {
      return -1;
    } else if (currentIndex === size - 1 && newIdx === 0) {
      return 1;
    } else {
      return newIdx > currentIndex ? 1 : -1;
    }
  }

  function getItemAt(index: number) {
    return items[toRange(index, size)];
  }

  /**
   * IntersectionObserver logic to set `isPlaying` based on carousel visibility
   */
  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        // Exit early and do nothing
        if (userPaused || isReducedMotion || !autoplay) return;

        if (entry.isIntersecting && !isIntersecting && !isPlaying) {
          if (loop || (!loop && !isEnd)) {
            setIsPlaying(true);
          }

          setIsIntersecting(true);
        }

        if (!entry.isIntersecting && isIntersecting) {
          setIsPlaying(false);
          setIsIntersecting(false);
        }
      });
    },
    [
      autoplay,
      loop,
      isIntersecting,
      isEnd,
      isPlaying,
      userPaused,
      isReducedMotion,
    ],
  );

  /**
   * Renderless component used to conditionally call `useIntersectionObserver`
   * because we don't need to observe anything if `isReducedMotion`.
   *
   * @example
   *
   * {autoplay && !isReducedMotion && <Observe refObj={containerRef} />}
   */
  function Observe({refObj}: ObserveProps) {
    useIntersectionObserver(refObj, observerCallback);
    return null;
  }

  return {
    currentIndex,
    currentItem,
    direction,
    endIndexChange,
    fireOnce,
    getDirection,
    getItemAt,
    getNextIndex,
    getNextItem,
    getPreviousIndex,
    getPreviousItem,
    globalAriaLabels,
    isEnd,
    isIntersecting,
    isPlaying,
    isReducedMotion,
    isStart,
    lastInteraction,
    next,
    newIndex,
    Observe,
    observerCallback,
    previous,
    setCurrentIndex,
    setIsPlaying,
    setNewIndex,
    setUserPaused,
    size,
    startIndexChangeTo,
    userPaused,
  };
}
